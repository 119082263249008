import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p"><em parentName="strong">{`*`}{`Our Annual free Thanksgiving morning WOD will be Today at 9:00am!
 All other classes and open gym will be cancelled for the day as well as
the 6:00am class on Friday.  Classes will be back to normal starting at
10:30am on Friday.  The Ville will open at 8:00am for Open gym.`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`Thanksgiving Day WOD`}</em></strong></p>
    <p>{`4 Person teams complete the following:`}</p>
    <p>{`1 Mile Row (1600M’s)`}</p>
    <p>{`100 S2OH (95/65, rx+ 135/95)`}</p>
    <p>{`200 Burpees`}</p>
    <p>{`300 Wall Balls (20/14)`}</p>
    <p>{`400 Situps`}</p>
    <p>{`1 Mile Run`}</p>
    <p>{`For time.`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`The next Strength Program will start Nov 26th.  For more info email
Eric at: fallscitystrength\\@gmail.com.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      